<!--项目画像项目 xwh 20211009-->
<template>
  <div class="base-container">
    <div class="main-wrapper">
      <div class="tag">互联网数据 / {{portraitData.projectName || '-'}}</div>
      <el-button class="backbtn" type="text" @click="back()">返回上级</el-button>

      <div v-if="isFang">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
            <span class="card-title">基本信息</span>
            </div>
            <div>
                <div class="card-list">
                    <div class="small-item">
                        <p>小区名称：<span>{{ portraitData.projectName||'-' }}</span></p>
                        <p>权属类别：<span>{{ portraitData.propertyCategory||'-' }}</span></p>
                    </div>
                    <div class="small-item">
                        <p>所属区域：<span>{{ portraitData.region||'-' }}</span></p>
                        <p>竣工时间：<span>{{ portraitData.endDate||'-' }}</span></p>
                    </div>
                    <div class="small-item">
                        <p>小区地址：<span>{{ portraitData.address||'-' }}</span></p>
                        <p>产权年限：<span>{{ portraitData.propertyDesc||'-' }}</span></p>
                    </div>
                </div>
                <div class="card-list">
                    <div class="small-item">
                        <p>总户数：<span>{{ portraitData.houseCount||'-' }}</span></p>
                        <p>绿化率：<span>{{ portraitData.greeningRate||'-' }}</span></p>
                    </div>
                    <div class="small-item">
                        <p>总建面积：<span>{{ portraitData.buildingArea||'-' }}</span></p>
                        <p>建筑类型：<span>{{ portraitData.constructionType||'-' }}</span></p>
                    </div>
                    <div class="small-item">
                        <p>容积率：<span>{{ portraitData.plotRate||'-' }}</span></p>
                        <p>所属商圈：<span>{{ portraitData.belongTo||'-' }}</span></p>
                    </div>
                </div>
                <div class="card-list">
                    <div class="small-item">
                        <p>开发商：<span>{{ portraitData.constructionCompany||'-' }}</span></p>
                        <p>房屋总数：<span>{{ portraitData.houseCount||'-' }}</span></p>
                    </div>
                    <div class="small-item">
                        <p>建筑年代：<span>{{ portraitData.constructionYear||'-' }}</span></p>
                        <p>楼栋总数：<span>{{ portraitData.buildingCount||'-' }}</span></p>
                    </div>
                    <div class="small-item">
                        <p>占地面积：<span>{{ portraitData.landArea||'-' }}</span></p>
                        <p>装修状况：<span>{{ portraitData.decorationCondition||'-' }}</span></p>
                    </div>
                </div>
                <div class="card-list no-border">
                    <div class="small-item">
                        <p>环线位置：<span>{{ portraitData.circleLineAddress||'-' }}</span></p>
                        <p>附加信息：<span>{{ portraitData.extraInfo||'-' }}</span></p>

                    </div>
                    <div class="small-item">
                        <p>项目特色：
                            <span class="bg-span" v-for="(item,index) in projectFeature" :key="index">{{ item }}</span>
                        </p>
                    </div>
                    <div class="small-item">
                        <p>小区入口：<span>{{ portraitData.entrance||'-' }}</span></p>
                    </div>
                </div>
            </div>
        </el-card>
        <el-card class="box-card">
            <div slot="header" class="clearfix">
            <span class="card-title">配套设施</span>
            </div>
            <div>
                <div class="card-list no-border">
                    <div class="small-item">
                        <p>统一供暖：<span>{{ portraitData.heating||'-' }}</span></p>
                        <p>停车费：<span>{{ portraitData.parkCost||'-' }}</span></p>
                    </div>
                    <div class="small-item">
                        <p>供水供电：<span>{{ portraitData.costType||'-' }}</span></p>
                        <p>车位管理费：<span>{{ portraitData.parkManageCost||'-' }}</span></p>
                    </div>
                    <div class="small-item">
                        <p>停车位：<span>{{ portraitData.parkingCount||'-' }}</span></p>
                        <p>通讯设备：<span>{{ portraitData.communicationEquipment||'-' }}</span></p>
                    </div>
                </div>
                <div class="card-list no-border">
                    <div class="small-item">
                        <p>安全管理：<span>{{ portraitData.security||'-' }}</span></p>
                        <p>交通状况：<span>{{ portraitData.transportCondition||'-' }}</span></p>
                    </div>
                    <div class="small-item">
                        <p>卫生服务：<span>{{ portraitData.healthService||'-' }}</span></p>
                        <p>周边设施：<span>{{ portraitData.transportCondition||'-' }}</span></p>
                    </div>
                </div>
            </div>
        </el-card>
        <el-card class="box-card">
            <div slot="header" class="clearfix">
            <span class="card-title">销售信息</span>
            </div>
            <div>
            <table class="info-table">
                <tr>
                    <th width="191">售楼电话</th>
                    <td width="383">{{ portraitData.inqueryPhoneNumber||'-' }}</td>
                    <th width="191">开盘时间</th>
                    <td width="383">{{ portraitData.saleDate||'-' }}</td>
                </tr>
                <tr>
                    <th>售楼地址</th>
                    <td>{{ portraitData.saleAddress||'-' }}</td>
                    <th>交房时间</th>
                    <td>{{ portraitData.deliverDate||'-' }}</td>
                </tr>
                <tr>
                    <th>销售状态</th>
                    <td>{{ portraitData.saleStatus||'-' }}</td>
                    <th>主力户型</th>
                    <td>{{ portraitData.mainHouseStructure||'-' }}</td>
                </tr>
            
            </table>
            </div>
        </el-card>
        <el-card class="box-card">
            <div slot="header" class="clearfix">
            <span class="card-title">物业信息</span>
            </div>
            <div>
                <div class="card-list no-border">
                    <div class="small-item">
                        <p>物业公司：<span>{{ portraitData.propertyCompany||'-' }}</span></p>
                        <p>物业办公地点：<span>{{ portraitData.propertyOfficeAddress||'-' }}</span></p>
                    </div>
                    <div class="small-item">
                        <p>物业类型：<span>{{ portraitData.propertyCategory||'-' }}</span></p>
                        <p>物业费描述：<span>{{ portraitData.propertyCostDesc||'-' }}</span></p>
                    </div>
                    <div class="small-item">
                        <p>物业办公电话：<span>{{ portraitData.propertyOfficePhoneNumber||'-' }}</span></p>
                    </div>
                </div>
            </div>
        </el-card>
      </div>
      <!-- 安居客版本 -->
      <div v-if="!isFang">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
            <span class="card-title">基本信息</span>
            </div>
            <div>
                <div class="card-list">
                    <div class="small-item">
                        <p>小区名称：<span>{{ portraitData.projectName||'-' }}</span></p>
                        <p>物业类型：<span>{{ portraitData.propertyCategory||'-' }}</span></p>
                    </div>
                    <div class="small-item">
                        <p>所属区域：<span>{{ portraitData.region||'-' }}</span></p>
                        <p>权属类别：<span>{{ portraitData.propertyCategory||'-' }}</span></p>
                    </div>
                    <div class="small-item">
                        <p>小区地址：<span>{{ portraitData.address||'-' }}</span></p>
                        <p>竣工时间：<span>{{ portraitData.endDate||'-' }}</span></p>
                    </div>
                </div>
                <div class="card-list">
                    <div class="small-item">
                        <p>产权年限：<span>{{ portraitData.propertyDesc||'-' }}</span></p>
                        <p>容积率：<span>{{ portraitData.plotRate||'-' }}</span></p>
                    </div>
                    <div class="small-item">
                        <p>总户数：<span>{{ portraitData.houseCount||'-' }}</span></p>
                        <p>绿化率：<span>{{ portraitData.greeningRate||'-' }}</span></p>
                    </div>
                    <div class="small-item">
                        <p>总建面积：<span>{{ portraitData.buildingArea||'-' }}</span></p>
                        <p>建筑类型：<span>{{ portraitData.constructionType||'-' }}</span></p>
                    </div>
                </div>
                <div class="card-list no-border">
                    <div class="small-item">
                        <p>所属商圈：<span>{{ portraitData.belongTo||'-' }}</span></p>
                    </div>
                    <div class="small-item">
                        <p>物业公司：<span>{{ portraitData.propertyCompany||'-' }}</span></p>
                    </div>
                    <div class="small-item">
                        <p>开发商：<span>{{ portraitData.constructionCompany||'-' }}</span></p>
                    </div>
                </div>
            
            </div>
        </el-card>
        <el-card class="box-card">
            <div slot="header" class="clearfix">
            <span class="card-title">配套设施</span>
            </div>
            <div>
                <div class="card-list no-border">
                    <div class="small-item">
                        <p>统一供暖：<span>{{ portraitData.heating||'-' }}</span></p>
                        <p>物业费：<span>{{ portraitData.propertyCost||'-' }}</span></p>
                    </div>
                    <div class="small-item">
                        <p>供水供电：<span>{{ portraitData.costType||'-' }}</span></p>
                        <p>停车费：<span>{{ portraitData.parkCost||'-' }}</span></p>
                    </div>
                    <div class="small-item">
                        <p>停车位：<span>{{ portraitData.parkingCount||'-' }}</span></p>
                        <p>车位管理费：<span>{{ portraitData.parkManageCost||'-' }}</span></p>
                    </div>
                </div>
            </div>
        </el-card>
    
      </div>
    </div>
  </div>
</template>

<script>
import {getStore, setStore,getLocalStore} from "@/util/store";
import {mapMutations} from "vuex";

export default {
  data() {
    return {
        portraitData:{},
        projectFeature:[],
        fromData: {},
        isShow: false,
        isTagShow: true,
        isFang:true,
    };
  },
  mounted() {
    this.portraitData = getStore('netData');
    console.log(this.portraitData)
    if(this.portraitData.source=='fang'){
        this.isFang = true;
    }else{
        this.isFang = false;
    }
  },
  created(){
      this.getReport(0,'','');
  },
  destroyed(){
      this.getReport(1,'','');
  },
  methods: {
    ...mapMutations(["reducePath", "addMenuList", "reduceMenuList"]),
    //展现点击上报
    getReport(recordType,modelName,clickName){
        this.axios.request({
            method: "post", 
            url: '/dms/saveUserBehaviorRecord',
            data: {
                pageName:'portraitProject',
                userName:getLocalStore('areainfo').userName,
                recordType:recordType,
                modelName:modelName,
                clickName:clickName,
                userId:getLocalStore('areainfo').id,
                ipAddress:getLocalStore('areainfo').ipAddress,
                userAddress:getLocalStore('areainfo').userAddress,
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
    },
    back(){
        this.reduceMenuList();//删除历史菜单中最后一个元素（当前页的路由）
        var $path = getStore("netfrom");
        this.$router.push({path: $path});
    }
 

  },
};
</script>

<style scoped lang="less">
    .clearfix:before,
    .clearfix:after {
        display: table;
        content: "";
    }
    .clearfix:after {
        clear: both;
    }
    .tag {
        color: #646566;
        margin: 16px 0;
    }
    .backbtn{
        position: absolute;
        right: 0;
        top: -10px;
    }
    .box-card {
        width: 100%;
        border-radius: 2px;
        box-shadow: none;
        border: none;
    }
    .card-title{
        font-size: 16px;
    }
    .card-list{
        border-bottom: 1px solid #F2F3F5;
        padding-bottom: 8px;
        margin-bottom: 16px;
        overflow: hidden;
    }
    .small-item{
        width: 33.3%;
        float: left;
        >p{
            margin-bottom: 8px;
            color: #969799;
            >span{
                color: #646566;
            }
            .bg-span{
                background-color: rgb(243, 242, 245);
                margin-right: 16px;
            }
        }
    }
    .no-border{
        border: none;
        margin: 0;
    }
    .el-card{
        margin-bottom: 24px;
    }
    
    .info-table{
        width: 100%;
        border-collapse: collapse;
        th{
            background: #F6F6F6;
            border: 1px solid #EBEBEB;
            padding: 8px 12px;
            text-align: right;
            font-weight: 400;
            color: #333333;
        }
        td{
            background: #FFFFFF;
            border: 1px solid #EBEBEB;
            padding: 8px;
            padding-left: 12px;
            color: #555555;
        }
    }
    .label{
        td{
            text-align: center;
        }
        th{
            text-align: center;
        }
        tr td:nth-child(2){
            text-align: left;
            span{
                margin-right: 12px;
                font-size: 12px;
            }
        }
        tr th:nth-child(3){
            text-align: left;
        }
    }
    .other-info span{
        background: #EDF4FF;
        color: #185BCE;
    }
    .cq-info span{
        background: #FFF5ED;
        color: #ED6A0C;
    }
    .cb-info span{
        background: #F2EEFF;
        color: #A044C2;
    }
    .fy-info span{
        background: #F0FAF2;
        color: #2DA641;
    }
    .td-info span{
        background: #E2F1F8;
        color: #2692C4;
    }
    .xs-info span{
        background: #F2F3F5;
        color: #646566;
    }
</style>